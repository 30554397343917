/* Api methods to call /api */

const API_BASE_URL = process.env.GATSBY_FUNCTIONS_BASE_URL;

const createClub = (data) => {
  const endpoint = `${API_BASE_URL}/clubs-create`;
  return fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};

const updateClub = (data) => {
  const endpoint = `${API_BASE_URL}/clubs-update`;
  return fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};

const sendClubNotification = (data) => {
  const endpoint = `${API_BASE_URL}/clubs-send-notification`;
  return fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};

export { createClub, updateClub, sendClubNotification };
