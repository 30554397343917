import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CreatableSelect from 'react-select/creatable';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

const suggestions = [
  { label: 'Aerobic' },
  { label: 'Aikido' },
  { label: 'American Football' },
  { label: 'Apnoetauchen' },
  { label: 'Aquathlon' },
  { label: 'Australian Football' },
  { label: 'Badminton' },
  { label: 'Ballett' },
  { label: 'Baseball' },
  { label: 'Basketball' },
  { label: 'Beachvolleyball' },
  { label: 'Biathlon' },
  { label: 'Billard' },
  { label: 'Bob und Rennrodel' },
  { label: 'Bodybuilding' },
  { label: 'Bogenschießen' },
  { label: 'Boogie-Woogie' },
  { label: 'Bouldern' },
  { label: 'Boule' },
  { label: 'Boxen' },
  { label: 'Brazilian Jiu-Jitsu' },
  { label: 'Capoeira' },
  { label: 'Calisthenics' },
  { label: 'Cheerleading' },
  { label: 'Cricket' },
  { label: 'Curling' },
  { label: 'Dart' },
  { label: 'Drachen- & Gleitschirmsport' },
  { label: 'Drachenboot' },
  { label: 'Einradsport' },
  { label: 'Eishockey' },
  { label: 'Eiskunstlauf' },
  { label: 'Eisschnelllauf' },
  { label: 'Eisstockschießen' },
  { label: 'Fallschirmsport' },
  { label: 'Faustball' },
  { label: 'Fechten' },
  { label: 'Finswimming' },
  { label: 'Flag Football' },
  { label: 'Floorball' },
  { label: 'Fußball' },
  { label: 'Futsal' },
  { label: 'Gerätturnen' },
  { label: 'Gewichtheben' },
  { label: 'Golf' },
  { label: 'Gymnastik Tanz' },
  { label: 'Handball' },
  { label: 'Hockey' },
  { label: 'Hurling' },
  { label: 'Indiaca' },
  { label: 'Inline Alpin' },
  { label: 'Inline Speedskating' },
  { label: 'Inline-Downhill' },
  { label: 'Inline-Skating' },
  { label: 'Inlinehockey' },
  { label: 'Ju-Jutsu' },
  { label: 'Judo' },
  { label: 'Kanusport' },
  { label: 'Karate' },
  { label: 'Kegeln' },
  { label: 'Kendo' },
  { label: 'Kickboxen' },
  { label: 'Kinderturnen' },
  { label: 'Kitesurfen' },
  { label: 'Korbball' },
  { label: 'Korfball' },
  { label: 'Kraftsport' },
  { label: 'Kung Fu' },
  { label: 'Kyūdō' },
  { label: 'Lacrosse' },
  { label: 'Leichtathletik' },
  { label: 'Moderner Fünfkampf' },
  { label: 'Motorbootsport' },
  { label: 'Motorflug' },
  { label: 'Motorsport' },
  { label: 'Mountainbike' },
  { label: 'Mountainbike-Orienteering' },
  { label: 'Muaythai' },
  { label: 'Nordic Walking' },
  { label: 'Nordische Kombination' },
  { label: 'Orientierungslauf' },
  { label: 'Orientierungstauchen' },
  { label: 'Parkour' },
  { label: 'Petanque' },
  { label: 'Polo' },
  { label: 'Präzisionsorientieren' },
  { label: 'Prellball' },
  { label: 'Quidditch' },
  { label: 'Racketlon' },
  { label: 'Racquetball' },
  { label: 'Radball' },
  { label: 'Radsport' },
  { label: 'Rasenkraftsport' },
  { label: 'Reiten' },
  { label: 'Rettungssport' },
  { label: 'Rhönradturnen' },
  { label: 'Rhythmische Gymnastik' },
  { label: 'Rhytmische Sportgymnastik' },
  { label: 'Ringen' },
  { label: 'Ringtennis' },
  { label: 'Rock’n’Roll' },
  { label: 'Rollhockey' },
  { label: 'Rollkunstlauf' },
  { label: 'Rope Skipping' },
  { label: 'Rudern' },
  { label: 'Rugby' },
  { label: 'Schach' },
  { label: 'Schleuderball' },
  { label: 'Schwertkampf' },
  { label: 'Schwimmen' },
  { label: 'Segelfliegen' },
  { label: 'Segeln' },
  { label: 'Short Track' },
  { label: 'Skateboard' },
  { label: 'Skeleton' },
  { label: 'Ski Alpin' },
  { label: 'Skibob' },
  { label: 'Skilanglauf' },
  { label: 'Ski-Orientierungslauf' },
  { label: 'Skispringen' },
  { label: 'Snowboard' },
  { label: 'Softball' },
  { label: 'Sportakrobatik' },
  { label: 'Sportklettern' },
  { label: 'Sportschießen' },
  { label: 'Squash' },
  { label: 'Surfen' },
  { label: 'Synchronschwimmen' },
  { label: 'Taekwondo' },
  { label: 'Tai Chi' },
  { label: 'Tanzen' },
  { label: 'Tauchen' },
  { label: 'Tchoukball' },
  { label: 'Team Gym' },
  { label: 'Tennis' },
  { label: 'Thaiboxen' },
  { label: 'Tischtennis' },
  { label: 'Trampolinturnen' },
  { label: 'Triathlon' },
  { label: 'Twirling' },
  { label: 'Ultimate Frisbee' },
  { label: 'Unterwasserhockey' },
  { label: 'Unterwasserrugby' },
  { label: 'Volleyball' },
  { label: 'Wasserball' },
  { label: 'Wasserski und Wakeboard' },
  { label: 'Wasserspringen' },
  { label: 'Windsurfen' },
  { label: 'Yoga' },
  { label: 'Zumba' },
].map((suggestion) => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 290,
  },
  input: {
    backgroundColor: theme.palette.grey['50'],
    display: 'flex',
    padding: 0,
    height: 'auto',
    minHeight: 56,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 12,
    bottom: 16,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 20,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

/* <FieldSportsAutocomplete> component */
const FieldSportsAutocomplete = React.forwardRef((props, ref) => {
  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };

  const classes = useStyles();
  const theme = useTheme();
  const [multi, setMulti] = React.useState(null);

  // Fire props.onChange() when multi changes
  React.useEffect(() => {
    props.onChange();
  }, [multi]);

  const handleChangeMulti = (value) => {
    setMulti(value);
  };

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <CreatableSelect
          ref={ref}
          classes={classes}
          styles={selectStyles}
          inputId="react-select-multiple"
          TextFieldProps={{
            // required: true,
            // label: 'Angebotene Sportarten',
            color: 'secondary',
            InputLabelProps: {
              htmlFor: 'react-select-multiple',
              shrink: true,
            },
            variant: 'outlined',
            error: props.error,
            classes: props.classes,
            helperText: props.helperText,
            onBlur: props.onBlur,
          }}
          placeholder={`${props.label} *`}
          formatCreateLabel={(input) => `Füge "${input}" hinzu...`}
          options={suggestions}
          components={components}
          value={multi}
          onChange={handleChangeMulti}
          isMulti
        />
      </NoSsr>
    </div>
  );
});
export default FieldSportsAutocomplete;
