import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

/* MUI Tinongo theme */
const theme = createMuiTheme(require('../../styles/theme/mui-theme'));
const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-root ': {
      backgroundColor: theme.palette.grey['50'],
    },
    '& input:focus ~ fieldset': {
      borderWidth: 2,
    },
    '&.isValid': {
      '& label': {
        color: theme.palette.success,
      },
      '& input ~ fieldset, & input:hover ~ fieldset, & input:focus ~ fieldset': {
        borderColor: theme.palette.success,
        borderLeftWidth: 6,
        padding: '4px',
      },
    },
    '&.isError': {
      '& label': {
        color: theme.palette.error,
      },
      '& input ~ fieldset, & input:hover ~ fieldset, & input:focus ~ fieldset': {
        borderColor: theme.palette.error,
      },
    },
  },
})(TextField);

/* <FieldAddressAutocomplete> component */
const FieldAddressAutocomplete = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const autocompleteService = React.useRef(null);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.description}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      disableOpenOnFocus
      renderInput={(params) => (
        <StyledTextField
          {...params}
          inputRef={ref}
          onChange={handleChange}
          onBlur={props.onBlur}
          classes={props.classes}
          label={props.label}
          helperText={props.helperText}
          error={props.error}
          color="secondary"
          variant="outlined"
          fullWidth
          required
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
});
export default FieldAddressAutocomplete;
