/* Material UI Tinongo theme customization */
module.exports = {
  palette: {
    primary: {
      light: '#8468ff',
      main: '#473be0',
      dark: '#000fad',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7b5c',
      main: '#ef4730',
      dark: '#b50004',
      contrastText: '#fff',
    },
    error: {
      main: '#ef4730',
    },
    warning: {
      main: '#ffdd26',
    },
    info: {
      main: '#473be0',
    },
    success: {
      main: '#63d37f',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
