//
// Gatsby Tinongo add-a-club page
// Route: /vereine/neu
//

import React from 'react';

import NoSsr from '@material-ui/core/NoSsr';

import AppShell from '../../components/app-shell';
import Content from '../../components/content';
import FormClubsCreate from '../../components/forms/form-clubs-create';
// import Section from '../../components/section';
import SEO from '../../components/seo';

export default function NewClub() {
  const header = (
    <header>
      <h1 className="pageTitle">Neuen Verein eintragen</h1>
    </header>
  );

  // const lead = (
  //   <Section className="bodyText" fullWidth>
  //     <p>
  //       So weit möglich, verlinken wir bei den Sportarten auf die Vereinssuchen
  //       der Sportverbände oder unseres Partners{' '}
  //       <a
  //         href="https://vereinscheck.de/"
  //         target="_blank"
  //         rel="noopener noreferrer">
  //         Vereinscheck.de
  //       </a>
  //       . Das ist zwar nicht sehr nutzerfreundlich, dennoch werden diese
  //       Verlinkungen sehr häufig angeklickt. Um die Suche nach einem Sport noch
  //       besser und sinnvoller zu gestalten, wollen wir Eltern die Möglichkeit
  //       bieten, auf einer Karte die Sportvereine in ihrer Umgebung zu finden.
  //     </p>
  //     <p>
  //       Dazu brauchen wir aber die Informationen der Vereine: Lediglich
  //       Vereinsname, Adresse, angebotene Sportarten und – wenn vorhanden – die
  //       Homepage. Diese Daten sind datenschutzrechtlich unbedenklich, da keine
  //       personenbezogenen Daten betroffen sind.{' '}
  //       <strong>
  //         Daher kann jeder seinen oder einen Sportverein eintragen. Kostenlos,
  //         unverbindlich und in 2 Minuten erledigt.
  //       </strong>
  //     </p>
  //   </Section>
  // );

  return (
    <>
      <SEO
        title="Verein eintragen"
        description="Hilf mit beim Aufbau der Vereinsdatenbank und trage
        Deinen Sportverein ein. Kostenlos, unverbindlich und mit einem Klick
        erledigt."
      />
      <AppShell>
        <Content>
          {header}
          <NoSsr>
            <FormClubsCreate />
          </NoSsr>
        </Content>
      </AppShell>
    </>
  );
}
