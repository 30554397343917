/* React Tinongo <FormAddClub> component */

import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import Recaptcha from 'react-recaptcha';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import {
  createMuiTheme,
  makeStyles,
  withStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FieldAddressAutocomplete from './field-address-autocomplete';
import FieldSportsAutocomplete from './field-sports-autocomplete';
import Snackbar from '../material-ui/snackbar';

import {
  createClub,
  updateClub,
  sendClubNotification,
} from './../../utils/api';

// MUI Tinongo theme
const theme = createMuiTheme(require('./../../styles/theme/mui-theme'));
const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: theme.spacing(4),
  },
  appBar: {
    position: 'relative',
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTable: {
    marginBottom: theme.spacing(4),
  },
}));

// Text field stylings
const StyledTextField = withStyles({
  root: {
    '& input, & .MuiInputBase-multiline ': {
      backgroundColor: theme.palette.grey['50'],
    },
    '& input:focus ~ fieldset': {
      borderWidth: 2,
    },
    '& textarea:focus ~ fieldset': {
      borderWidth: 2,
    },
    '&.isValid': {
      '& label': {
        color: theme.palette.success,
      },
      '& input ~ fieldset, & input:hover ~ fieldset, & input:focus ~ fieldset': {
        borderColor: theme.palette.success,
        borderLeftWidth: 6,
        padding: '4px',
      },
      '& textarea ~ fieldset, & textarea:hover ~ fieldset, & textarea:focus ~ fieldset': {
        borderColor: theme.palette.success,
        borderLeftWidth: 6,
        padding: '4px',
      },
    },
    '&.isError': {
      '& label': {
        color: theme.palette.error,
      },
      '& input ~ fieldset, & input:hover ~ fieldset, & input:focus ~ fieldset': {
        borderColor: theme.palette.error,
      },
      '& textarea ~ fieldset, & textarea:hover ~ fieldset, & textarea:focus ~ fieldset': {
        borderColor: theme.palette.error,
      },
    },
  },
})(TextField);

// Submit button stylings
const SubmitButton = withStyles({
  root: {
    marginTop: theme.spacing(2),
  },
})(Button);

// Full screen dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* <FormClubsCreate> component */
export default function FormClubsCreate() {
  //
  // --- Styles & form field default props ---
  //
  const classes = useStyles();
  const textFieldProps = {
    color: 'secondary',
    fullWidth: true,
    variant: 'outlined',
  };
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('xs'));

  //
  // --- States ---
  //
  const initialFormState = {
    step: 1,
    fieldsValid: false,
    recaptchaValid: false,
    isProcessing: false,
    isUpdate: false,
  };
  const initialFieldState = {
    value: undefined,
    valid: undefined,
  };
  const initialFieldStateBool = {
    value: false,
    valid: undefined,
  };
  const [skipDuplicateCheck, setSkipDuplicateCheck] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [newClub, setNewClub] = useState(undefined);
  const [foundClub, setFoundClub] = useState(undefined);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: undefined,
    variant: undefined,
  });
  const [form, setForm] = useState(initialFormState);
  const [club, setClub] = useState(initialFieldState);
  const [address, setAddress] = useState(initialFieldState);
  const [sports, setSports] = useState(initialFieldState);
  const [sportsForHandicapped, setSportsForHandicapped] = useState(
    initialFieldStateBool
  );
  const [kiss, setKiss] = useState(initialFieldStateBool);
  const [url, setUrl] = useState(initialFieldState);
  const [misc, setMisc] = useState(initialFieldState);

  //
  // --- Fields config ---
  //
  const fields = {
    club: {
      state: club,
      fieldName: 'verein',
      label: 'Verein',
      required: true,
      pattern: /\w{3,}/iu,
      helperText: '',
      errorMessage: 'Bitte trage den vollständigen Namen des Vereins ein',
    },
    address: {
      state: address,
      fieldName: 'adresse',
      label: 'Adresse der Haupsportstätte',
      required: true,
      pattern: /\w{5,}/iu,
      helperText: '',
      errorMessage:
        'Wir benötigen eine vollständige Adresse, um den Verein einzutragen',
    },
    sports: {
      state: sports,
      fieldName: 'sportarten',
      label: 'Angebotene Sportarten',
      required: true,
      helperText: '',
      errorMessage: 'Welche Sportarten bietet der Verein an?',
    },
    sportsForHandicapped: {
      state: sportsForHandicapped,
      fieldName: 'behindertensport',
      label: 'Der Verein bietet Behindertensport an',
      required: false,
      helperText: '',
      errorMessage: 'Bitte wähle eine Option aus',
    },
    kiss: {
      state: kiss,
      fieldName: 'kiss',
      label: 'Der Verein bietet KISS an',
      required: false,
      helperText: '',
      errorMessage: 'Bitte wähle eine Option aus',
    },
    url: {
      state: url,
      fieldName: 'url',
      label: 'Homepage',
      required: false,
      pattern: /^[a-zA-Z0-9][a-zA-Z0-9-._/~:?#[\]@!$&'()*+,;=%]*$/i,
      helperText: '',
      errorMessage:
        'Das ist keine gültige URL. Bitte gib die komplette Webadresse der Homepage ein',
    },
    misc: {
      state: misc,
      fieldName: 'sonstiges',
      label: 'Sonstiges',
      required: false,
      helperText: '',
      errorMessage: '',
    },
  };

  //
  // --- Effect Hooks ---
  //
  useEffect(() => {
    function allFieldsValid() {
      const formFields = Object.keys(fields);
      const fieldsValid = formFields.map((field) => {
        const valid = fields[field].state.valid;
        const required = fields[field].required;
        return valid || (!required && valid === undefined);
      });
      const allValid = fieldsValid.indexOf(false) === -1;

      allValid
        ? setForm((prevState) => {
            return { ...prevState, fieldsValid: true };
          })
        : setForm((prevState) => {
            return { ...prevState, fieldsValid: false };
          });
    }

    allFieldsValid();
  }, [club, address, sports, sportsForHandicapped, kiss, url, misc]);

  useEffect(() => {
    // Only refire submission if skipDuplicateCheck true
    if (skipDuplicateCheck) {
      handleUpdateDialogClose();
      handleCreate();
    }
  }, [skipDuplicateCheck]);

  //
  // --- Event handlers ---
  //
  const handleCreate = (event) => {
    if (event) event.preventDefault();

    const data = createFormData();

    setForm((prevState) => {
      return { ...prevState, isProcessing: true };
    });

    createClub(data)
      .then((response) => {
        console.log('API response', response);

        const success = response.success;
        const error = response.error;
        const message = response.message;

        // Set app state
        if (success) {
          setNewClub(response.newClub);
          setSnackbar({
            message: message,
            variant: 'success',
            open: true,
          });
          setForm((prevState) => {
            return { ...prevState, step: 2 };
          });

          sendClubNotification(response.newClub);
        } else if (error && error.type === 'duplicateFound') {
          setFoundClub(error.foundClub);
          setNewClub(error.newClub);
          setOpenUpdateDialog(true);
        } else if (error && error.message) {
          setSnackbar({
            message: error.message,
            variant: 'error',
            open: true,
          });
        } else if (error) {
          setSnackbar({
            message: error,
            variant: 'error',
            open: true,
          });
        } else {
          setSnackbar({
            message: 'Es ist ein unbekannter Fehler aufgetreten',
            variant: 'error',
            open: true,
          });
        }
      })
      .catch((error) => {
        console.error('API error', error);

        // Set app state
        setSnackbar({
          message: 'Es ist ein unbekannter Fehler aufgetreten',
          variant: 'error',
          open: true,
        });
      })
      .finally(() => {
        setSkipDuplicateCheck(false);
        setForm((prevState) => {
          return { ...prevState, isProcessing: false };
        });
      });
  };

  const handleUpdate = (event) => {
    if (event) event.preventDefault();

    const data = newClub;
    data.objectID = foundClub.objectID;

    setForm((prevState) => {
      return { ...prevState, isProcessing: true };
    });

    handleUpdateDialogClose();

    updateClub(data)
      .then((response) => {
        console.log('API response', response);

        const success = response.success;
        const error = response.error;
        const message = response.message;

        // Set app state
        if (success) {
          setNewClub(response.updatedClub);
          setSnackbar({
            message: message,
            variant: 'success',
            open: true,
          });
          setForm((prevState) => {
            return { ...prevState, step: 2, isUpdate: true };
          });

          response.updatedClub.isUpdate = true;
          sendClubNotification(response.updatedClub);
        } else if (error && error.message) {
          setSnackbar({
            message: error.message,
            variant: 'error',
            open: true,
          });
        } else if (error) {
          setSnackbar({
            message: error,
            variant: 'error',
            open: true,
          });
        }
      })
      .catch((error) => {
        console.error('API error', error);

        // Set app state
        setSnackbar({
          message: 'Es ist ein unbekannter Fehler aufgetreten',
          variant: 'error',
          open: true,
        });
      })
      .finally(() => {
        setSkipDuplicateCheck(false);
        setForm((prevState) => {
          return { ...prevState, isProcessing: false };
        });
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prevState) => {
      return {
        ...prevState,
        open: false,
        message: undefined,
      };
    });
  };

  const handleUpdateDialogClose = () => {
    setOpenUpdateDialog(false);
  };

  const handleUpdateDialogCreate = (event) => {
    event.preventDefault();

    setSkipDuplicateCheck(true);
  };

  const handleReset = () => {
    // Reset config and data
    setSkipDuplicateCheck(false);
    setNewClub(undefined);
    setFoundClub(undefined);

    // Reset fields
    setClub(initialFieldState);
    setAddress(initialFieldState);
    setSports(initialFieldState);
    setSportsForHandicapped(initialFieldStateBool);
    setKiss(initialFieldState);
    setUrl(initialFieldState);
    setMisc(initialFieldState);

    // Reset form
    setForm(initialFormState);
  };

  const handleRecaptchaVerified = (response) => {
    setForm((prevState) => {
      return { ...prevState, recaptchaValid: true };
    });
  };

  const handleRecaptchaExpired = (response) => {
    setForm((prevState) => {
      return { ...prevState, recaptchaValid: false };
    });
  };

  //
  // --- Form fields JSX & change listeners---
  //
  const onClubChange = (newClub) => {
    setClub({
      value: newClub,
      valid: newClub.length > 0 && fields.club.pattern.test(newClub),
    });
  };
  const fieldClub = (
    <StyledTextField
      name={fields.club.fieldName}
      onBlur={(e) => onClubChange(e.target.value)}
      classes={{
        root:
          club.valid !== undefined ? (club.valid ? 'isValid' : 'isError') : '',
      }}
      label={fields.club.label}
      helperText={
        club.valid !== undefined && !club.valid
          ? fields.club.errorMessage
          : fields.club.helperText
      }
      error={club.valid !== undefined ? !club.valid : false}
      required={fields.club.required}
      type="text"
      {...textFieldProps}
    />
  );

  const addressInput = useRef(null);
  const onAddressChange = (newAddress) => {
    setAddress({
      value: newAddress,
      valid: newAddress.length > 0 && fields.address.pattern.test(newAddress),
    });
  };
  const fieldAddress = (
    <FieldAddressAutocomplete
      ref={addressInput}
      onBlur={(e) => onAddressChange(addressInput.current.value)}
      classes={{
        root:
          address.valid !== undefined
            ? address.valid
              ? 'isValid'
              : 'isError'
            : '',
      }}
      label={fields.address.label}
      helperText={
        address.valid !== undefined && !address.valid
          ? fields.address.errorMessage
          : fields.address.helperText
      }
      error={address.valid !== undefined ? !address.valid : false}
    />
  );

  const sportsInput = useRef(null);
  const onSportsChange = (newSports) => {
    if (newSports) {
      const newVal = newSports.state.value;
      setSports({
        value: newVal,
        valid: newVal && newVal.length > 0,
      });
    }
  };
  const fieldSports = (
    <FieldSportsAutocomplete
      ref={sportsInput}
      onChange={(e) => onSportsChange(sportsInput.current)}
      onBlur={(e) => onSportsChange(sportsInput.current)}
      classes={{
        root:
          sports.valid !== undefined
            ? sports.valid
              ? 'isValid'
              : 'isError'
            : '',
      }}
      label={fields.sports.label}
      helperText={
        sports.valid !== undefined && !sports.valid
          ? fields.sports.errorMessage
          : fields.sports.helperText
      }
      error={sports.valid !== undefined ? !sports.valid : false}
    />
  );

  const onSportsForHandicappedChange = (newVal) => {
    setSportsForHandicapped({
      value: newVal,
      valid: true,
    });
  };
  const fieldSportsForHandicapped = (
    <FormGroup row>
      <FormControlLabel
        control={<Switch />}
        name={fields.sportsForHandicapped.fieldName}
        onChange={(e) => onSportsForHandicappedChange(e.target.checked)}
        label={fields.sportsForHandicapped.label}
      />
    </FormGroup>
  );

  const onKissChange = (newVal) => {
    setKiss({
      value: newVal,
      valid: true,
    });
  };
  const fieldKiss = (
    <FormGroup row>
      <FormControlLabel
        control={<Switch />}
        name={fields.kiss.fieldName}
        onChange={(e) => onKissChange(e.target.checked)}
        label={fields.kiss.label}
      />
    </FormGroup>
  );

  const onUrlChange = (target) => {
    const val = target.value;
    const scheme = /^(http|https):(\/\/)/;
    const newVal = scheme.test(val) || val.length === 0 ? val : 'http://' + val;

    target.value = newVal;

    setUrl({
      value: newVal,
      valid: newVal.length > 0 ? fields.url.pattern.test(newVal) : undefined,
    });
  };
  const fieldUrl = (
    <StyledTextField
      name={fields.url.fieldName}
      onBlur={(e) => onUrlChange(e.target)}
      classes={{
        root:
          url.valid !== undefined ? (url.valid ? 'isValid' : 'isError') : '',
      }}
      label={fields.url.label}
      helperText={
        url.valid !== undefined && !url.valid
          ? fields.url.errorMessage
          : fields.url.helperText
      }
      error={url.valid !== undefined ? !url.valid : false}
      required={fields.url.required}
      type="url"
      {...textFieldProps}
    />
  );

  const onMiscChange = (newMisc) => {
    setMisc({
      value: newMisc,
      valid: newMisc.length > 0 ? true : undefined,
    });
  };
  const fieldMisc = (
    <StyledTextField
      name={fields.misc.fieldName}
      onBlur={(e) => onMiscChange(e.target.value)}
      classes={{
        root:
          misc.valid !== undefined ? (misc.valid ? 'isValid' : 'isError') : '',
      }}
      label={fields.misc.label}
      error={misc.valid !== undefined ? !misc.valid : false}
      required={fields.misc.required}
      type="text"
      multiline
      {...textFieldProps}
    />
  );

  const createFormData = () => {
    return {
      [`${fields.club.fieldName}`]: club.value,
      [`${fields.address.fieldName}`]: address.value,
      [`${fields.sports.fieldName}`]: sports.value,
      [`${fields.sportsForHandicapped.fieldName}`]: sportsForHandicapped.value,
      [`${fields.kiss.fieldName}`]: kiss.value,
      [`${fields.url.fieldName}`]: url.value || null,
      [`${fields.misc.fieldName}`]: misc.value || null,
      skipDuplicateCheck: skipDuplicateCheck,
    };
  };
  const submit = (
    <>
      <Recaptcha
        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
        verifyCallback={handleRecaptchaVerified}
        expiredCallback={handleRecaptchaExpired}
        hl="de"
      />
      <SubmitButton
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        disabled={
          form.fieldsValid && form.recaptchaValid && !form.isProcessing
            ? false
            : true
        }
        endIcon={
          !form.isProcessing ? (
            <Icon>send</Icon>
          ) : (
            <CircularProgress size={24} color="secondary" />
          )
        }>
        Verein kostenlos eintragen
      </SubmitButton>
    </>
  );

  let rows = [];
  const createTableData = (name, foundVal, newVal) => {
    return { name, foundVal, newVal };
  };

  if (foundClub && newClub) {
    rows = [
      createTableData('Verein', foundClub.verein, newClub.verein),
      createTableData('Straße', foundClub.strasse, newClub.strasse),
      createTableData('PLZ', foundClub.plz, newClub.plz),
      createTableData('Ort', foundClub.ort, newClub.ort),
      createTableData(
        'Sportarten',
        foundClub.sportarten.toString().replace(/,/g, ', '),
        newClub.sportarten.toString().replace(/,/g, ', ')
      ),
      createTableData(
        'Behindertensport',
        foundClub.behindertensport ? 'Ja' : 'Nein',
        newClub.behindertensport ? 'Ja' : 'Nein'
      ),
      createTableData(
        'KISS',
        foundClub.kiss ? 'Ja' : 'Nein',
        newClub.kiss ? 'Ja' : 'Nein'
      ),
      createTableData('Homepage', foundClub.url, newClub.url),
      createTableData('Sonstiges', foundClub.sonstiges, newClub.sonstiges),
    ];
  } else if (newClub) {
    rows = [
      createTableData('Verein', null, newClub.verein),
      createTableData('Straße', null, newClub.strasse),
      createTableData('PLZ', null, newClub.plz),
      createTableData('Ort', null, newClub.ort),
      createTableData(
        'Sportarten',
        null,
        newClub.sportarten.toString().replace(/,/g, ', ')
      ),
      createTableData(
        'Behindertensport',
        null,
        newClub.behindertensport ? 'Ja' : 'Nein'
      ),
      createTableData('KISS', null, newClub.kiss ? 'Ja' : 'Nein'),
      createTableData('Homepage', null, newClub.url),
      createTableData('Sonstiges', null, newClub.sonstiges),
    ];
  }

  const updateDialog = (
    <Dialog
      open={openUpdateDialog}
      onClose={handleUpdateDialogClose}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
      maxWidth="md"
      fullWidth
      fullScreen={fullScreenDialog}
      TransitionComponent={Transition}>
      {fullScreenDialog && (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateDialogClose}
              aria-label="close">
              <Icon>close</Icon>
            </IconButton>
            <Typography
              id="update-dialog-title"
              variant="h6"
              className={classes.appBarTitle}>
              Duplikat gefunden
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {!fullScreenDialog && (
        <DialogTitle disableTypography>
          <Typography variant="h6" id="update-dialog-title">
            Duplikat gefunden
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleUpdateDialogClose}
            aria-label="close">
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent id="update-dialog-description" dividers>
        <DialogContentText>
          Es wurde in derselben Postleitzahl ein Verein mit folgenden Daten
          gefunden:
        </DialogContentText>
        {foundClub && newClub && (
          <Table aria-label="Gefunden vs. Neu" className={classes.dialogTable}>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">
                  <strong>Gefunden</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Neu</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <strong>{row.name}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <span
                      style={
                        row.foundVal !== row.newVal
                          ? { backgroundColor: '#ffdd26' }
                          : {}
                      }>
                      {row.foundVal}
                    </span>
                  </TableCell>
                  <TableCell
                    style={row.foundVal !== row.newVal ? {} : {}}
                    align="right">
                    <span
                      style={
                        row.foundVal !== row.newVal
                          ? { backgroundColor: '#ffdd26' }
                          : {}
                      }>
                      {row.newVal}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <DialogContentText>
          Handelt es sich bei dem gefundenen und Deinem neuen Eintrag um
          denselben Sportverein? Soll der gefundene Verein mit Deinen Angaben
          aktualisiert werden? Dann klicke bitte &raquo;Verein
          aktualisieren&laquo;, sonst auf &raquo;Neuen Verein eintragen&laquo;.
        </DialogContentText>
        <DialogContentText>
          Zum Abbrechen klicke das X-Symbol oben.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => handleUpdate(e)}
          variant="contained"
          color="primary"
          endIcon={<Icon>update</Icon>}>
          Verein aktualisieren
        </Button>
        <Button
          onClick={(e) => handleUpdateDialogCreate(e)}
          variant="contained"
          color="secondary"
          endIcon={<Icon>send</Icon>}>
          Neuen Verein eintragen
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      {form.step === 1 && (
        <>
          <form onSubmit={handleCreate} noValidate>
            <div className={classes.formRow}>{fieldClub}</div>
            <div className={classes.formRow}>{fieldAddress}</div>
            <div className={classes.formRow}>{fieldSports}</div>
            <div className={classes.formRow}>
              {fieldSportsForHandicapped}
              {fieldKiss}
            </div>
            <div className={classes.formRow}>{fieldUrl}</div>
            <div className={classes.formRow}>{fieldMisc}</div>
            <div className={classes.formRow}>{submit}</div>
          </form>
          {updateDialog}
        </>
      )}
      {form.step === 2 && (
        <Fade in={form.step === 2}>
          <div className="bodyText">
            <Paper
              style={{
                padding: theme.spacing(3, 2),
              }}>
              <p>
                {form.isUpdate ? (
                  <>Danke für Deine Unterstützung und die Aktualisierung. </>
                ) : (
                  <>Danke für Deine Unterstützung und den neuen Eintrag. </>
                )}
                <Link to={`/suche/vereine/name/${newClub.verein}`}>
                  Hier findest Du Deinen Verein &raquo;{newClub.verein}&laquo;
                  in der Vereinsübersicht
                </Link>
                .
              </p>
              <p>
                <Button
                  onClick={handleReset}
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon>add</Icon>}>
                  Weiteren Verein eintragen
                </Button>
              </p>
            </Paper>

            <Paper
              style={{
                marginTop: theme.spacing(4),
                padding: theme.spacing(3, 2),
              }}>
              <h2>Vereins-Newsletter</h2>
              <p>
                Du willst auf dem Laufenden bleiben, wenn wir Aktionen mit
                Vereinen planen oder es wichtige Neuigkeiten und Möglichkeiten
                für Vereine gibt?{' '}
                <a
                  href="https://subscribe.newsletter2go.com/?n2g=btbr8xjn-kj3czx37-14ww&amp;_ga=2.104338306.329671479.1574339365-764722668.1574339365"
                  ta
                  rget="_blank"
                  rel="noopener noreferrer">
                  Dann trag dich hier in unseren Vereins-Newsletter ein.
                </a>
              </p>
            </Paper>

            {newClub && (
              <Paper
                style={{
                  marginTop: theme.spacing(4),
                }}>
                <Table
                  aria-label={newClub.verein}
                  className={classes.dialogTable}>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          <strong>{row.name}</strong>
                        </TableCell>
                        <TableCell align="right">{row.newVal}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>
        </Fade>
      )}

      <Snackbar
        message={snackbar.message}
        variant={snackbar.variant}
        open={snackbar.open}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
}
